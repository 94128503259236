<template>
    <div class="card" @click="handleClick()">
        <div class="card-media">
            <img :src="data.cover" />
        </div>
        <div class="body">
            <!-- 标题 -->
            <div class="title">
                <span class="textOverflow">{{ data.name }}</span>
            </div>
            <!-- 简介 -->
            <div class="description" :title="data.description">
                <span>简介：{{ data.description || '暂无简介' }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: {
        data: {
            require: true,
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {};
    },
    methods: {
        // 查看详情
        handleClick() {
            this.$router.push({
                path: '/education/videoPlay',
                query: {
                    courseId: this.data.id
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.card img {
    width: 100%;
    height: 100%;
}

.card {
    margin-bottom: 20px;
    position: relative;
    border: 1px solid #e5e5e5;
    cursor: pointer;
    // transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    // &:hover {
    //     border-color: $--color-primary;
    //     box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.2);
    // }

    &-media {
        position: relative;
        height: auto;
        background-color: #f6f6f6;
        overflow: hidden;
        width: 100%;
        aspect-ratio: 8 / 6;
    }

    .body {
        width: 92%;
        height: 160px;
        margin: 0 auto;
        padding: 20px 0;
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        color: #497ddc;
    }

    .description {
        margin-top: 8px;
        color: #797979;
        font-size: 12px;
        line-height: 24px;
    }

    .description span {
        height: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
}

.card:hover,
.card:hover .title,
.card:hover .description,
.card:hover .body {
    color: #fff;
    background: #467ed1;
}

@media screen and (max-width: 900px) {
    .card {
        .body {
            height: 150px;
        }

        .title {
            font-size: 1.8vw;
        }

        .description {
            font-size: 1.5vw;
        }
    }
}

@media screen and (max-width: 700px) {
    .card {
        .body {
            height: 180px;
        }

        .title {
            font-size: 20px;
            margin: 10px 0;
        }

        .description {
            font-size: 16px;
        }
    }
}
</style>
