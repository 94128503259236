<template>
    <div class="body">
        <skeleton type="card" :count="9" :loading="loading" :data="data.content">
            <ul>
                <li :span="8" v-for="(item, index) in data.content" :key="index">
                    <Card :data="item.content" />
                </li>
            </ul>
        </skeleton>
        <!-- 分页 -->
        <pagination :pageSizes="[9, 18, 27, 36, 45, 100]" :pageSize="request.pageSize" :currentPage="request.pageIndex"
            :total="data.total" @current-change="pageCurrentChange" @size-change="pageSizeChange">
        </pagination>
    </div>
</template>

<script>
import Card from '@/views/education/components/card.vue';
export default {
    components: {
        Card
    },
    data() {
        return {
            request: {
                queryString: '',
                resourceType: 'COURSE',
                pageSize: 9,
                pageIndex: 1
            },
            data: {
                content: [],
                total: 0
            },
            loading: false
        };
    },
    created() {
        const { page } = this.$route.query;
        this.request.pageIndex = Number(page) || 1;
        this.loadData();
    },
    methods: {
        async loadData() {
            this.request.queryString = this.$route.query.key || '';
            this.loading = true;
            const res = await this.$api.search(this.request)
                .catch(() => {
                    this.loading = false;
                });
            this.loading = false;
            if (res.code == 200) {
                this.data.content = res.data.searchHits;
                this.data.total = Number(res.data.totalHits);
                this.data.content.forEach(item => {
                    item.content.createUser = {};
                });
                const ids = res.data.searchHits.map(obj => obj.content.id);
                const userIds = res.data.searchHits.map(
                    obj => obj.content.createdUserId
                );
            }
        },
        // 当前页变化
        pageCurrentChange(value) {
            let query = Object.assign({}, this.$route.query, {
                page: value
            });
            this.$router.replace({
                name: 'Search',
                query
            });
            this.request.pageIndex = value;
            this.loadData();
            this.$emit('current-change', value);
        },
        // 分页数变化
        pageSizeChange(value) {
            this.request.pageSize = value;
            this.loadData();
        },
        update() {
            this.request.pageIndex = 1;
            this.loadData();
        }
    }
};
</script>

<style lang="scss" scoped>
.body ul {
    width: 100%;
    margin-left: 1%;
}

.body li {
    width: 31.3%;
    float: left;
    margin-right: 2%;
}

@media screen and (max-width: 700px) {
    .body ul {
        width: 100%;
        margin: 0 auto;
    }

    .body li {
        width: 100%;
    }
}
</style>
